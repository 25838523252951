import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventDetail = _resolveComponent("EventDetail")!
  const _component_SectionDetail = _resolveComponent("SectionDetail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_EventDetail, {
      "section-list": _ctx.sectionList,
      "onUpdate:section-list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sectionList) = $event)),
      mode: "edit",
      onChange: _ctx.handleDetailChange
    }, null, 8, ["section-list", "onChange"]), [
      [_vShow, _ctx.stage === 'event']
    ]),
    (_ctx.stage === 'section')
      ? (_openBlock(), _createBlock(_component_SectionDetail, {
          key: 0,
          "section-list": _ctx.sectionList,
          "onUpdate:section-list": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sectionList) = $event)),
          "init-section": _ctx.currentSection,
          onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.stage = 'event'))
        }, null, 8, ["section-list", "init-section"]))
      : _createCommentVNode("", true)
  ], 64))
}